import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedState } from '@stores/shared/shared.state';
export const SHARED_STATE_NAME = 'shared';
export const selectSharedState =
  createFeatureSelector<SharedState>(SHARED_STATE_NAME);
export const selectIsDarkTheme = createSelector(
  selectSharedState,
  state => state.isDarkMode
);
export const selectIsSidebarOpen = createSelector(
  selectSharedState,
  state => state.isSideBarOpen
);
export const selectIsLoading = createSelector(
  selectSharedState,
  state => state.loading.status || state.loading.reqCounter > 0
);
