import { ErrorProp, SnackbarData } from '@models';
import { createAction, props } from '@ngrx/store';

export const TOGGLE_THEME = '[shared state] toggle dark theme';
export const TOGGLE_SIDEBAR = '[shared state] set sidebar';
export const SET_LOADING_STATUS = '[shared state] set loading status';
export const SET_LOADING_COUNTER = '[shared state] set loading counter';
export const SET_ERROR_ALERT = '[shared state] set error alert';
export const SHOW_SNACK_BAR = '[shared state] show snack bar';
export const toggleTheme = createAction(TOGGLE_THEME);
export const toggleSidebar = createAction(TOGGLE_SIDEBAR);
export const showSnackbar = createAction(
  SHOW_SNACK_BAR,
  props<{ data: SnackbarData; duration?: number }>()
);
export const setLoadingStatus = createAction(
  SET_LOADING_STATUS,
  props<{ value: boolean }>()
);
export const setLoadingCounter = createAction(
  SET_LOADING_COUNTER,
  props<{ value: number }>()
);
export const setErrorToast = createAction(SET_ERROR_ALERT, props<ErrorProp>());
